require('jquery-ui')
const { DateTime } = require('luxon')

import { Chart, LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Decimation } from 'chart.js'
Chart.register(LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Decimation);
import 'chartjs-adapter-luxon';

$(function() {
  if (typeof startDate !== 'undefined') {
    $('div#datepicker').datepicker({
      dateFormat: 'yy-mm-dd',
      minDate: startDate,
      maxDate: endDate,
      onSelect: selectDate
    })

    selectDate(endDate)
  }

  if (typeof chart_data !== 'undefined') {
    configureSpeedChart()
  }
})

function selectDate(dateText)
{
  let dt
  if (dateText instanceof Date) {
    dt = DateTime.fromJSDate(dateText)
  } else {
    dt = DateTime.fromISO(dateText)
  }

  $('.activity_table').addClass('hidden')
  $(`.activity_table_${dt.toFormat('yyyyMMdd')}`).removeClass('hidden')
}

function configureSpeedChart()
{
  for(const o of chart_data.datasets[0].data) {
    o.x = DateTime.fromSeconds(o.x)
  }
  const canvas = $('div.chart > canvas')[0].getContext('2d');
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    parsing: false,
    plugins: {
      title: {
        display: true,
        text: 'Speed Data'
      },
      decimation: {
        enabled: true,
      }
    },
    scales: {
      x: {
        type: 'time',
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true
        }
      }
    }
  };

  new Chart(canvas, {
      'type': 'line',
      'data': chart_data,
      'options': options
  });
}